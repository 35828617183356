import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "bg-white",
  style: {"position":"fixed","bottom":"0","width":"100%","z-index":"2002"}
}
const _hoisted_2 = { class: "q-pa-md" }
const _hoisted_3 = { class: "flex no-wrap justify-between q-gutter-x-md" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { style: {"flex-grow":"1"} }

import MessageUserButton from 'components/adverts/MessageUserButton.vue';
import {shareAdvert, shareItem} from 'src/apps/adverts/helper';
import {useQuasar} from 'quasar';
import {useTracking} from 'src/etc/tracking';
import {showLoginDialog} from 'src/etc/helper';
import {PropType, ref} from 'vue';
import {Advert} from 'src/models/advert';
import {Item} from 'src/models/market';


export default /*@__PURE__*/_defineComponent({
  __name: 'FixedAdvertDetailFooterBar',
  props: {
  advert: {
    type: Object as PropType<Advert>,
    required: false
  },
  item: {
    type: Object as PropType<Item>,
    required: false
  },
  phoneNumber: {
    type: String,
    required: false
  },
  canClickPhone: {
    type: Boolean,
    default: true
  }
},
  setup(__props) {

const props = __props

const loadingShare = ref(false)
const $q = useQuasar()

function share() {
  loadingShare.value = true
  if (props.advert) {
    shareAdvert($q, props.advert).finally(() => {
      loadingShare.value = false
    })
  } else if (props.item) {
    shareItem($q, props.item).finally(() => {
      loadingShare.value = false
    })
  }
}

function clickPhone() {
  if (!props.canClickPhone) {
    showLoginDialog()
    return
  }
  const tracker = useTracking()
  const url = `tel:${props.phoneNumber}`
  tracker.trackInteractionPhone(props.advert ? 'advert' : 'item')
  window.open(url, '_blank')
}



return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_separator),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            class: "full-height",
            style: {"aspect-ratio":"1"},
            color: "grey-4",
            "text-color": "dark",
            rounded: "",
            unelevated: "",
            loading: loadingShare.value,
            onClick: share
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-share-alt" })
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        (__props.phoneNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_q_btn, {
                class: "full-height",
                style: {"aspect-ratio":"1"},
                color: "secondary",
                rounded: "",
                unelevated: "",
                onClick: clickPhone
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "fas fa-phone" })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(MessageUserButton, {
            "advert-uuid": __props.advert?.uuid,
            "item-uuid": __props.item?.uuid,
            class: "rounded-borders full-width",
            "show-modal": false,
            label: "Nachricht schreiben",
            big: "",
            size: "1rem"
          }, null, 8, ["advert-uuid", "item-uuid"])
        ])
      ])
    ])
  ]))
}
}

})