import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex column no-wrap full-height" }
const _hoisted_2 = {
  class: "container q-pt-sm",
  style: {"flex-grow":"0"}
}
const _hoisted_3 = { class: "flex items-center no-wrap" }
const _hoisted_4 = { style: {"flex-grow":"1"} }
const _hoisted_5 = {
  class: "scroll",
  style: {"flex-grow":"1"}
}
const _hoisted_6 = {
  key: 0,
  class: "container"
}
const _hoisted_7 = { style: {"font-size":"1.1rem"} }
const _hoisted_8 = { class: "full-width ellipsis" }
const _hoisted_9 = {
  key: 1,
  class: "container",
  style: {"font-size":"1.1rem"}
}
const _hoisted_10 = { class: "full-width ellipsis" }
const _hoisted_11 = {
  key: 2,
  class: "container"
}
const _hoisted_12 = { style: {"font-size":"1.1rem"} }
const _hoisted_13 = { class: "full-width ellipsis" }
const _hoisted_14 = { class: "flex justify-end" }

import {useDialogPluginComponent} from 'quasar'
import {onMounted, PropType, ref} from 'vue';
import {AutocompleteResponse, SearchAdapter} from 'src/apps/search/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchTextInputDialog',
  props: {
  loading: {type: Boolean, default: false},
  searchAdapter: {
    type: Object as PropType<SearchAdapter>,
    required: true
  },
  searchTextProp: {
    type: String,
    required: false,
    default: ''
  },
},
  emits: [
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const props = __props

const history = ref<Array<any>>([])
const searchText = ref(props.searchTextProp)
const autocompleteResults = ref<AutocompleteResponse[]>([])
const isUnchanged = ref(true)

const _loading = ref(false)




function fetchAutocomplete() {
  autocompleteResults.value = []
  if (!searchText.value) {
    return
  }
  _loading.value = true
  props.searchAdapter?.fetchAutocomplete(searchText.value).then(resp => {
    autocompleteResults.value = resp
  }).finally(() => {
    _loading.value = false
  })
}

const {dialogRef, onDialogOK} = useDialogPluginComponent()

function onOKClick() {
  onDialogOK(searchText.value)
}

function clickAutocomplete(item) {
  searchText.value = item.text
  onOKClick()
}

function clickHistory(item) {
  searchText.value = item.search
  onOKClick()
}


function fetchSearchHistory() {
  props.searchAdapter?.fetchHistory().then(data => {
    history.value = data
  })
}

function onKeyUp(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    onOKClick()
  }
}

onMounted(() => {
  fetchSearchHistory()
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: _ctx.$q.screen.lt.sm,
    onHide: onOKClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "q-dialog-plugin" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    modelValue: searchText.value,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
                      _cache[1] || (_cache[1] = () => { fetchAutocomplete(); isUnchanged.value = false })
                    ],
                    type: "search",
                    flat: "",
                    placeholder: "Wonach suchst du?",
                    class: "full-height",
                    style: {"font-size":"1.2rem"},
                    clearable: "",
                    rounded: "",
                    autofocus: "",
                    loading: __props.loading || _loading.value,
                    debounce: "500",
                    onKeyup: onKeyUp
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        icon: 'fas fa-arrow-left',
                        round: "",
                        color: "white",
                        "text-color": "dark",
                        "aria-label": "close",
                        style: {"margin-left":"-8px"},
                        flat: "",
                        onClick: onOKClick
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "loading"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              ((!searchText.value?.length || isUnchanged.value) && history.value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-muted text-small" }, "Letzte Suchen", -1)),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_q_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(history.value, (item) => {
                            return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                              key: item.search,
                              class: "q-px-none q-py-md",
                              clickable: "",
                              onClick: ($event: any) => (clickHistory(item))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_avatar, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "fas fa-history" })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_8, _toDisplayString(item.search), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])), [
                              [_directive_ripple]
                            ])
                          }), 128))
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (searchText.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_q_list, null, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          class: "q-px-none q-py-md",
                          clickable: "",
                          onClick: _unref(onDialogOK)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, { avatar: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_avatar, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, { name: "fas fa-search" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_10, _toDisplayString(searchText.value), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])), [
                          [_directive_ripple]
                        ])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (autocompleteResults.value && searchText.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_q_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(autocompleteResults.value, (item) => {
                            return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                              key: item.text,
                              class: "q-px-none q-py-md",
                              clickable: "",
                              onClick: ($event: any) => (clickAutocomplete(item))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { avatar: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_avatar, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "fas fa-search" })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createTextVNode(_toDisplayString(item.text) + " ", 1),
                                      (item.source === 'service_name')
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _cache[3] || (_cache[3] = _createTextVNode("  ")),
                                            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-small" }, "Service", -1))
                                          ], 64))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])), [
                              [_directive_ripple]
                            ])
                          }), 128))
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.$q.screen.gt.sm)
              ? (_openBlock(), _createBlock(_component_q_card_section, {
                  key: 0,
                  style: {"flex-grow":"1"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_q_btn, {
                        unelevated: "",
                        "no-caps": "",
                        rounded: "",
                        color: "dark",
                        onClick: _unref(onDialogOK)
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Suchen")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["maximized"]))
}
}

})