import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass(["c-btn--special", {'q-py-md': _ctx.big}]),
    unelevated: "",
    loading: _ctx.loading,
    "no-caps": "",
    onClick: _ctx.click
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.hasIcon)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_q_icon, {
                name: "fas fa-comment-dots",
                size: "xs"
              }),
              _cache[0] || (_cache[0] = _createTextVNode("  "))
            ], 64))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
      ])
    ]),
    _: 3
  }, 8, ["class", "loading", "onClick"]))
}